<template>
  <div>
    <div class="news-card ar-card">
      <div style="margin-bottom:6px; display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
        <div>
          {{ $moment(news.dateCreated).locale('ru').format('LL') }}
        </div>
        <div v-if="isModerator">
          <el-icon size="1.8em" class="news-card__header--btn" @click="createNewsModal = true">
            <Pen />
          </el-icon>
          <el-icon size="2em" class="news-card__header--btn" @click="deleteNews()">
            <Delete />
          </el-icon>
        </div>
      </div>
      <div style="font-size:1.5555555555555556em; margin-bottom:0.5em">
        {{ news.title }}
      </div>
      <div style="word-wrap: break-word ;">
        {{ news.content }}
      </div>
    </div>
    <CreateNewsModal
      v-model="createNewsModal"
      :news="news"
      @update="$emit('update')"
    />
  </div>
</template>

<script>
import CreateNewsModal from '@/components/modals/CreateNewsModal.vue';
import { Delete, Pen } from '@/assets/icons/index.js';
import {
  ElMessage,
} from 'element-plus';

export default {
  name: 'PostCard',
  components: {
    Delete,
    Pen,
    CreateNewsModal,
  },
  props: {
    isModerator: Boolean,
    news: Object,
  },
  data() {
    return {
      createNewsModal: false,
    };
  },
  methods: {
    async deleteNews() {
      try {
        await this.$axios.delete(`/news/delete-news-post/${this.news.id}`, {});
        this.$emit('update');
        ElMessage({ message: 'Новость успешно удалена', type: 'success' });
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      }
    },
  },
};
</script>
