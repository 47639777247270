<template>
  <div style="width: 100%;">
    <div v-if="isModerator" class="release-header mb4">
      Статьи
    </div>
    <el-button v-if="isModerator" type="primary" class="mb4" @click="createNewsModal = true">
      Создать новость
    </el-button>
    <el-form v-if="isModerator" label-position="top">
      <el-form-item label="Дней на модерацию">
        <div>
          <el-input v-model="days" style="width:15rem" size="large" />
          <el-button type="primary" size="large" class="ml2" :loading="loading" @click="setDays">
            +
          </el-button>
        </div>
      </el-form-item>
    </el-form>
    <el-row :gutter="20">
      <el-col v-for="news in newsList" :key="news.id" class="news-card__wrapper">
        <PostCard :is-moderator="isModerator" :news="news" @update="getNews();" />
      </el-col>
      <div v-if="newsList.length === 0" class="news-card " style="width:50%; height:15rem; align-items: center; display: flex; flex-direction: row; justify-content: center;">
        Нет новостей
      </div>
    </el-row>
    <template v-if="!isModerator">
      <div class="release-header mt5 ar-h1">
        Предстоящие релизы
      </div>
      <div class="release-slider mt3 mb4">
        <div style="width:15rem">
          Средний срок выпуска
        </div>
        <div style="position: relative;" class="days-slider">
          <div class="days-slider__line" style="" />
          <div class="days-slider__days" :class="sldierClass">
            {{ days > 9 ? '9+' : days }}
          </div>
        </div>
      </div>
      <div v-if="releaseList.length === 0" class="news-card " style="width:50%; height:15rem; align-items: center; display: flex; flex-direction: row; justify-content: center;">
        Нет подходящих релизов
      </div>
      <el-row v-else :gutter="20">
        <el-col v-for="release in releaseList" :key="release.id" class="news-card__wrapper">
          <ReleaseCard :release="release" />
        </el-col>
      </el-row>
    </template>
    <CreateNewsModal
      v-model="createNewsModal"
      @update="getNews()"
    />
  </div>
</template>

<script>

import ReleaseCard from '@/components/cards/ReleaseCard.vue';
import { mapGetters } from 'vuex';
import PostCard from '@/components/cards/PostCard.vue';
import CreateNewsModal from '@/components/modals/CreateNewsModal.vue';

import {
  ElMessage,
} from 'element-plus';

export default {
  components: {
    PostCard,
    ReleaseCard,
    CreateNewsModal,
  },
  name: 'Main',
  data: () => ({
    createNewsModal: false,
    days: null,
    newsList: [],
    loading: false,
    releaseList: [],
  }),
  mounted() {
    this.getNews();
    this.getDays();
    this.getReleases();
  },
  computed: {
    ...mapGetters(['isModerator']),
    sldierClass() {
      if (this.days <= 3) {
        return 'days-slider__days--green';
      } if (this.days > 3 && this.days <= 6) {
        return 'days-slider__days--yellow';
      } if (this.days > 6 && this.days <= 9) {
        return 'days-slider__days--orange';
      }
      return 'days-slider__days--red';
    },
  },
  methods: {
    async setDays() {
      try {
        this.loading = true;
        const params = { days: +this.days };
        await this.$axios.post('/moderators-time/add-time', params);
        this.getDays();
        ElMessage({ message: 'Время установлено', type: 'success' });
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      } finally {
        this.loading = false;
      }
    },
    async getDays() {
      try {
        const { data } = await this.$axios.get('/moderators-time/get-last-time');
        this.days = data;
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      }
    },
    async getNews() {
      try {
        const { data } = await this.$axios.get('/news/get-last-news');
        this.newsList = [];
        this.newsList = data;
        this.selectedNews = null;
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      }
    },
    async getReleases() {
      try {
        const { data } = await this.$axios.post('/dynamic-advices/get-all-advices-by-user', {});
        this.releaseList = data.incomingReleaseAdvices;
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      }
    },
  },
};

</script>

<style lang="scss">
@media screen and (max-width: 127.77777777777778em) {
  .news-card__wrapper {
    flex-basis:100%!important;
  }
}
  .days-slider{
    &__line{
      height:0.5rem;
      width:7rem;
      background-color:$subGrey2;
      border-radius: 0.25rem;
    }

    &__days{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: absolute;
      width:2rem;
      height:2rem;
      border-radius: 50%;
      bottom:-0.7rem;
      color:white;
      font-weight: 400;
      &--green{
        left:0rem;
        background-color:#5DD052;
      }
      &--yellow{
        left:2rem;
        background-color:#E9BE25;
      }
      &--orange{
        left:4rem;
        background-color:#E87306;
      }
      &--red{
        left:6rem;
        background-color:#C50053;
      }
    }
  }

  .release-header {
    width:27.77777777777778em;
    font-size: 1.7777777777777777em;
    padding-bottom: 0.5em;
    border-bottom: 2px solid $whiteOpacity;
  }

  .release-slider{
    align-items: center;
    display: flex;
    width:25rem;
    background-color: $subGrey3;
    padding: 1em 2em;
    border-radius: 2em;
  }

   .news-card {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 2em 2em;
      margin-bottom:1em;
      transition: .3s;

  &:hover{
    transform: scale(1.01)
  }

  &__wrapper{
    flex-basis:50%;
    max-width:50%;
  }

  &__header {
    display: flex;
    flex-direction: row;

    &--btn{
      padding: 0.25rem;
      cursor: pointer;
      transition: 0.2s;
      // width:2rem;
      // height:2rem;
      border-radius: 50%;
      font-weight:500;

      &:hover{
        background-color: rgba(255,255,255,.2);
      }
    }

    &--info{
      width:calc(100% - 170px);
      padding: 0.5em;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    &--name{
      font-size: 1.35em !important;
      width:calc(100% - 5.555555555555555em);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1;
      font-weight: 500;
    }

    &--artist{
      font-size: 1.35em !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1;
    }
  }

  &__data {

    &--fields{
      font-size: 1em;
      display: flex;
      flex-direction: row;

      & :first-child{
        width:4em;
        margin-right: 0.5em;
        color: $primaryBlue;
      }
      & :last-child{
        font-weight: 300;
        margin-left: 0.5em;
      }
    }
  }
 }
</style>
